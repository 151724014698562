// Approximated collapsed size so that there are ~three lines of text

import React, { useState } from 'react';
import { richText } from "../../util/richText";
import { InlineTextButton } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import css from './ExpandableBio.module.css';
import { string } from 'prop-types';
import truncate from 'lodash/truncate';

// in the desktop layout in the author section of the ListingPage.
const BIO_COLLAPSED_LENGTH = 170;
const MIN_LENGTH_FOR_LONG_WORDS = 20;

const truncated = s => {
  return truncate(s, {
    length: BIO_COLLAPSED_LENGTH,

    // Allow truncated text end only in specific characters. This will
    // make the truncated text shorter than the length if the original
    // text has to be shortened and the substring ends in a separator.
    //
    // This ensures that the final text doesn't get cut in the middle
    // of a word.
    separator: /\s|,|\.|:|;/,
    omission: '…',
  });
};

const ExpandableBio = props => {
  const [expand, setExpand] = useState(false);
  const { className, bio } = props;
  const bioWithLinks = richText(bio, {
    linkify: true,
    longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
    longWordClass: css.longWord,
  });
  const truncatedBio = richText(truncated(bio), {
    linkify: true,
    longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
    longWordClass: css.longWord,
    breakChars: '/',
  });

  const handleShowMoreClick = () => {
    setExpand(!expand);
  };
  const showMore = (
    <InlineTextButton rootClassName={css.showMore} onClick={handleShowMoreClick}>
      <FormattedMessage id="ExpandableBio.showMore" />
    </InlineTextButton>
  );
  const showLess = (
    <InlineTextButton rootClassName={css.showLess} onClick={handleShowMoreClick}>
      <FormattedMessage id="ExpandableBio.showLess" />
    </InlineTextButton>
  );
  return (
    <div className={className}>
      <p>
        {expand ? bioWithLinks : truncatedBio}
        {bio.length >= BIO_COLLAPSED_LENGTH && !expand ? showMore : null}

      </p>
      {bio.length >= BIO_COLLAPSED_LENGTH && expand && showLess}
    </div>

  );
};

ExpandableBio.defaultProps = { className: null };

ExpandableBio.propTypes = {
  className: string,
  bio: string.isRequired,
};

export default ExpandableBio;