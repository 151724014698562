import React, { useState } from "react";
import css from './ToolTip.module.css';

export default function Tooltip({ children, title, ...rest }) {
  const [visible, setVisible] = useState(false);

  return (
    <div
      className={css.tooltipContainer}
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      {children}
      {visible && <div className={css.tooltip}>{title}</div>}
    </div>
  );
}
