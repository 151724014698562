import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import {
  array,
  arrayOf,
  bool,
  func,
  node,
  number,
  object,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import loadable from '@loadable/component';
import classNames from 'classnames';
import omit from 'lodash/omit';

import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import {
  displayDeliveryPickup,
  displayDeliveryShipping,
  displayPrice,
} from '../../util/configHelpers';
import {
  propTypes,
  LISTING_STATE_CLOSED,
  LINE_ITEM_NIGHT,
  LINE_ITEM_DAY,
  LINE_ITEM_ITEM,
  LINE_ITEM_HOUR,
  STOCK_MULTIPLE_ITEMS,
  STOCK_INFINITE_MULTIPLE_ITEMS,
} from '../../util/types';
import { formatMoney } from '../../util/currency';
import { parse, stringify } from '../../util/urlHelpers';
import { userDisplayNameAsString } from '../../util/data';
import {
  INQUIRY_PROCESS_NAME,
  getSupportedProcessesInfo,
  isBookingProcess,
  isPurchaseProcess,
  resolveLatestProcessName,
} from '../../transactions/transaction';

import { ModalInMobile, PrimaryButton, AvatarSmall, H1, H2, Modal, SecondaryButton } from '../../components';

import css from './OrderPanel.module.css';

const BookingTimeForm = loadable(() =>
  import(/* webpackChunkName: "BookingTimeForm" */ './BookingTimeForm/BookingTimeForm')
);
const BookingDatesForm = loadable(() =>
  import(/* webpackChunkName: "BookingDatesForm" */ './BookingDatesForm/BookingDatesForm')
);
const InquiryWithoutPaymentForm = loadable(() =>
  import(
    /* webpackChunkName: "InquiryWithoutPaymentForm" */ './InquiryWithoutPaymentForm/InquiryWithoutPaymentForm'
  )
);
const ProductOrderForm = loadable(() =>
  import(/* webpackChunkName: "ProductOrderForm" */ './ProductOrderForm/ProductOrderForm')
);

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;
const TODAY = new Date();

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const openOrderModal = (isOwnListing, isClosed, history, location) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0);
  } else {
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ ...parse(search), orderOpen: true })}`;
    history.push(`${pathname}${searchString}`, state);
  }
};

const closeOrderModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'orderOpen');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const handleSubmit = (
  isOwnListing,
  isClosed,
  isInquiryWithoutPayment,
  onSubmit,
  history,
  location
) => {
  // TODO: currently, inquiry-process does not have any form to ask more order data.
  // We can submit without opening any inquiry/order modal.
  return isInquiryWithoutPayment
    ? () => onSubmit({})
    : () => openOrderModal(isOwnListing, isClosed, history, location);
};

const dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'short' };

const PriceMaybe = props => {
  const {
    price,
    publicData,
    validListingTypes,
    intl,
    marketplaceCurrency,
    showCurrencyMismatch = false,
  } = props;
  const { listingType, unitType } = publicData || {};
  return null;

  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice || !price) {
    return null;
  }

  // Get formatted price or currency code if the currency does not match with marketplace currency
  const { formattedPrice, priceTitle } = priceData(price, marketplaceCurrency, intl);
  // TODO: In CTA, we don't have space to show proper error message for a mismatch of marketplace currency
  //       Instead, we show the currency code in place of the price
  return showCurrencyMismatch ? (
    <div className={css.priceContainerInCTA}>
      <div className={css.priceValue} title={priceTitle}>
        {formattedPrice}
      </div>
      <div className={css.perUnitInCTA}>
        <FormattedMessage id="OrderPanel.perUnit" values={{ unitType }} />
      </div>
    </div>
  ) : (
    <div className={css.priceContainer}>
      <p className={css.price}>{formatMoney(intl, price)}</p>
      <div className={css.perUnit}>
        <FormattedMessage id="OrderPanel.perUnit" values={{ unitType }} />
      </div>
    </div>
  );
};

const OrderPanel = props => {
  const {
    rootClassName,
    className,
    titleClassName,
    listing,
    validListingTypes,
    lineItemUnitType: lineItemUnitTypeMaybe,
    isOwnListing,
    onSubmit,
    title,
    titleDesktop,
    author,
    authorLink,
    onManageDisableScrolling,
    onFetchTimeSlots,
    monthlyTimeSlots,
    history,
    location,
    intl,
    onFetchTransactionLineItems,
    onContactUser,
    lineItems,
    marketplaceCurrency,
    dayCountAvailableForBooking,
    marketplaceName,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    payoutDetailsWarning,
    isTransactionPage,
  } = props;

  const publicData = listing?.attributes?.publicData || {};
  const { listingType, unitType, transactionProcessAlias = '' } = publicData || {};
  const processName = resolveLatestProcessName(transactionProcessAlias.split('/')[0]);
  const lineItemUnitType = lineItemUnitTypeMaybe || `line-item/${unitType}`;

  const price = listing?.attributes?.price;
  const isPaymentProcess = processName !== INQUIRY_PROCESS_NAME;

  const showPriceMissing = isPaymentProcess && !price;
  const PriceMissing = () => {
    return (
      <p className={css.error}>
        <FormattedMessage id="OrderPanel.listingPriceMissing" />
      </p>
    );
  };
  const showInvalidCurrency = isPaymentProcess && price?.currency !== marketplaceCurrency;
  const InvalidCurrency = () => {
    return (
      <p className={css.error}>
        <FormattedMessage id="OrderPanel.listingCurrencyInvalid" />
      </p>
    );
  };

  const timeZone = listing?.attributes?.availabilityPlan?.timezone;
  const isClosed = listing?.attributes?.state === LISTING_STATE_CLOSED;

  const isBooking = isBookingProcess(processName);
  const shouldHaveBookingTime = isBooking && [LINE_ITEM_HOUR].includes(lineItemUnitType);
  const showBookingTimeForm = shouldHaveBookingTime && !isClosed && timeZone;

  const shouldHaveBookingDates =
    isBooking && [LINE_ITEM_DAY, LINE_ITEM_NIGHT].includes(lineItemUnitType);
  const showBookingDatesForm = shouldHaveBookingDates && !isClosed && timeZone;

  // The listing resource has a relationship: `currentStock`,
  // which you should include when making API calls.
  const isPurchase = isPurchaseProcess(processName);
  const currentStock = listing.currentStock?.attributes?.quantity;
  const isOutOfStock = isPurchase && lineItemUnitType === LINE_ITEM_ITEM && currentStock === 0;

  // Show form only when stock is fully loaded. This avoids "Out of stock" UI by
  // default before all data has been downloaded.
  const showProductOrderForm = isPurchase && typeof currentStock === 'number';

  const showInquiryForm = processName === INQUIRY_PROCESS_NAME;

  const supportedProcessesInfo = getSupportedProcessesInfo();
  const isKnownProcess = supportedProcessesInfo.map(info => info.name).includes(processName);

  const { pickupEnabled, shippingEnabled } = listing?.attributes?.publicData || {};

  const listingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const displayShipping = displayDeliveryShipping(listingTypeConfig);
  const displayPickup = displayDeliveryPickup(listingTypeConfig);
  const allowOrdersOfMultipleItems = [STOCK_MULTIPLE_ITEMS, STOCK_INFINITE_MULTIPLE_ITEMS].includes(
    listingTypeConfig?.stockType
  );

  const showClosedListingHelpText = listing.id && isClosed;
  const isOrderOpen = !!parse(location.search).orderOpen;

  const subTitleText = showClosedListingHelpText
    ? intl.formatMessage({ id: 'OrderPanel.subTitleClosedListing' })
    : null;

  const authorDisplayName = userDisplayNameAsString(author, '');
  const authorUserType = author.attributes.profile.publicData.userType;
  const authorLoanOfficerURL = author.attributes.profile.publicData?.personal_application_url;

  const classes = classNames(rootClassName || css.root, className);
  const titleClasses = classNames(titleClassName || css.orderTitle);

  const loanOfficeButtonURL = authorUserType === 'loan_officer' && authorLoanOfficerURL && (
    <a
      className={css.loanOfficerButton}
      href={!isOwnListing ? authorLoanOfficerURL : undefined}
      target="_blank"
    >
      <div style={{ marginRight: '10px' }}>
        <FormattedMessage id="OrderPanel.authorLoanOfficerURL" />
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M16.3333 10.8333V15.8333C16.3333 16.2754 16.1577 16.6993 15.8452 17.0118C15.5326 17.3244 15.1087 17.5 14.6667 17.5H4.66667C4.22464 17.5 3.80072 17.3244 3.48816 17.0118C3.17559 16.6993 3 16.2754 3 15.8333V5.83333C3 5.39131 3.17559 4.96738 3.48816 4.65482C3.80072 4.34226 4.22464 4.16667 4.66667 4.16667H9.66667V5.83333H4.66667V15.8333H14.6667V10.8333H16.3333ZM11.3333 2.5V4.16667H15.155L8.66083 10.6608L9.83917 11.8392L16.3333 5.345V9.16667H18V2.5H11.3333Z"
          fill="white"
        />
      </svg>
    </a>
  );

  const bookingActionButton = isClosed ? (
    <div className={css.closedListingButton}>
      <FormattedMessage id="OrderPanel.closedListingButtonText" />
    </div>
  ) : (
    <div
      className={css.requestButton}
      onClick={handleSubmit(isOwnListing, isClosed, showInquiryForm, onSubmit, history, location)}
      disabled={isOutOfStock}
    >
      {isBooking ? (<div className={css.requestButtonText}>
        <div style={{ marginRight: '10px' }}><FormattedMessage id="OrderPanel.ctaButtonMessageBooking" /></div>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path d="M16.25 2.5H13.75V1.25H12.5V2.5H7.5V1.25H6.25V2.5H3.75C3.0625 2.5 2.5 3.0625 2.5 3.75V16.25C2.5 16.9375 3.0625 17.5 3.75 17.5H16.25C16.9375 17.5 17.5 16.9375 17.5 16.25V3.75C17.5 3.0625 16.9375 2.5 16.25 2.5ZM16.25 16.25H3.75V7.5H16.25V16.25ZM16.25 6.25H3.75V3.75H6.25V5H7.5V3.75H12.5V5H13.75V3.75H16.25V6.25Z" fill="#26A8B0" />
        </svg>
      </div>

      ) : isOutOfStock ? (
        <FormattedMessage id="OrderPanel.ctaButtonMessageNoStock" />
      ) : isPurchase ? (
        <FormattedMessage id="OrderPanel.ctaButtonMessagePurchase" />
      ) : (
        <FormattedMessage id="OrderPanel.ctaButtonMessageInquiry" />
      )}
    </div>
  );

  const askAQuestions = (<div
    className={css.askAQuestion}
    onClick={() => {
      if (!isOwnListing) onContactUser();
    }}
  >
    <div style={{ marginRight: '10px' }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
      >
        <path
          d="M5 7.99926C5 8.29173 4.89464 8.57223 4.70711 8.77904C4.51957 8.98585 4.26522 9.10203 4 9.10203C3.73478 9.10203 3.48043 8.98585 3.29289 8.77904C3.10536 8.57223 3 8.29173 3 7.99926C3 7.70678 3.10536 7.42629 3.29289 7.21948C3.48043 7.01267 3.73478 6.89648 4 6.89648C4.26522 6.89648 4.51957 7.01267 4.70711 7.21948C4.89464 7.42629 5 7.70678 5 7.99926ZM9 7.99926C9 8.29173 8.89464 8.57223 8.70711 8.77904C8.51957 8.98585 8.26522 9.10203 8 9.10203C7.73478 9.10203 7.48043 8.98585 7.29289 8.77904C7.10536 8.57223 7 8.29173 7 7.99926C7 7.70678 7.10536 7.42629 7.29289 7.21948C7.48043 7.01267 7.73478 6.89648 8 6.89648C8.26522 6.89648 8.51957 7.01267 8.70711 7.21948C8.89464 7.42629 9 7.70678 9 7.99926ZM12 9.10203C12.2652 9.10203 12.5196 8.98585 12.7071 8.77904C12.8946 8.57223 13 8.29173 13 7.99926C13 7.70678 12.8946 7.42629 12.7071 7.21948C12.5196 7.01267 12.2652 6.89648 12 6.89648C11.7348 6.89648 11.4804 7.01267 11.2929 7.21948C11.1054 7.42629 11 7.70678 11 7.99926C11 8.29173 11.1054 8.57223 11.2929 8.77904C11.4804 8.98585 11.7348 9.10203 12 9.10203Z"
          fill="#4A4A4A"
        />
        <path
          d="M2.165 16.6046L2.185 16.6002C4.015 16.1999 5.133 15.6717 5.653 15.3816C6.41859 15.6068 7.20765 15.7203 8 15.7191C12.418 15.7191 16 12.263 16 7.99969C16 3.73636 12.418 0.280273 8 0.280273C3.582 0.280273 0 3.73636 0 7.99969C0 9.94057 0.743 11.716 1.97 13.0724C1.88416 13.9437 1.70835 14.8014 1.446 15.6287L1.443 15.6408C1.36844 15.8778 1.28707 16.112 1.199 16.3433C1.12 16.5484 1.273 16.7778 1.472 16.7425C1.70366 16.7007 1.93469 16.6547 2.165 16.6046ZM2.965 13.1772C2.97901 13.0158 2.96055 12.8529 2.91094 12.7002C2.86133 12.5475 2.7818 12.4088 2.678 12.2939C1.618 11.1205 1 9.61856 1 7.99969C1 4.47963 4.004 1.38305 8 1.38305C11.996 1.38305 15 4.47963 15 7.99969C15 11.5208 11.996 14.6163 8 14.6163C7.2951 14.6175 6.59311 14.5166 5.912 14.3164C5.67398 14.246 5.42079 14.2751 5.201 14.398C4.814 14.6141 3.961 15.0266 2.567 15.3828C2.76148 14.6628 2.89472 13.9244 2.965 13.1772Z"
          fill="#4A4A4A"
        />
      </svg>
    </div>
    <FormattedMessage id="OrderPanel.ctaButtonMessageInquiry" />
  </div>);

  const formPage = (showPriceMissing ? (
    <PriceMissing />
  ) : showInvalidCurrency ? (
    <InvalidCurrency />
  ) : showBookingTimeForm ? (
    <BookingTimeForm
      className={css.bookingForm}
      formId="OrderPanelBookingTimeForm"
      lineItemUnitType={lineItemUnitType}
      onSubmit={onSubmit}
      price={price}
      marketplaceCurrency={marketplaceCurrency}
      dayCountAvailableForBooking={dayCountAvailableForBooking}
      listingId={listing.id}
      isOwnListing={isOwnListing}
      monthlyTimeSlots={monthlyTimeSlots}
      onFetchTimeSlots={onFetchTimeSlots}
      startDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
      endDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
      timeZone={timeZone}
      marketplaceName={marketplaceName}
      onFetchTransactionLineItems={onFetchTransactionLineItems}
      lineItems={lineItems}
      fetchLineItemsInProgress={fetchLineItemsInProgress}
      fetchLineItemsError={fetchLineItemsError}
      payoutDetailsWarning={payoutDetailsWarning}
    />
  ) : showBookingDatesForm ? (
    <BookingDatesForm
      className={css.bookingForm}
      formId="OrderPanelBookingDatesForm"
      lineItemUnitType={lineItemUnitType}
      onSubmit={onSubmit}
      price={price}
      marketplaceCurrency={marketplaceCurrency}
      dayCountAvailableForBooking={dayCountAvailableForBooking}
      listingId={listing.id}
      isOwnListing={isOwnListing}
      monthlyTimeSlots={monthlyTimeSlots}
      onFetchTimeSlots={onFetchTimeSlots}
      timeZone={timeZone}
      marketplaceName={marketplaceName}
      onFetchTransactionLineItems={onFetchTransactionLineItems}
      lineItems={lineItems}
      fetchLineItemsInProgress={fetchLineItemsInProgress}
      fetchLineItemsError={fetchLineItemsError}
      payoutDetailsWarning={payoutDetailsWarning}
    />
  ) : showProductOrderForm ? (
    <ProductOrderForm
      formId="OrderPanelProductOrderForm"
      onSubmit={onSubmit}
      price={price}
      marketplaceCurrency={marketplaceCurrency}
      currentStock={currentStock}
      allowOrdersOfMultipleItems={allowOrdersOfMultipleItems}
      pickupEnabled={pickupEnabled && displayPickup}
      shippingEnabled={shippingEnabled && displayShipping}
      displayDeliveryMethod={displayPickup || displayShipping}
      listingId={listing.id}
      isOwnListing={isOwnListing}
      marketplaceName={marketplaceName}
      onFetchTransactionLineItems={onFetchTransactionLineItems}
      onContactUser={onContactUser}
      lineItems={lineItems}
      fetchLineItemsInProgress={fetchLineItemsInProgress}
      fetchLineItemsError={fetchLineItemsError}
      payoutDetailsWarning={payoutDetailsWarning}
    />
  ) : showInquiryForm ? (
    <InquiryWithoutPaymentForm formId="OrderPanelInquiryForm" onSubmit={onSubmit} />
  ) : !isKnownProcess ? (
    <p className={css.errorSidebar}>
      <FormattedMessage id="OrderPanel.unknownTransactionProcess" />
    </p>
  ) : null);


  return (
    <div className={classes}>
      <div className={css.DesktopView}>
        <div className={css.modalHeading}>
          <H1 className={css.heading}>{title}</H1>
        </div>

        <div className={css.orderHeading}>
          {titleDesktop ? titleDesktop : <H2 className={titleClasses}>{title}</H2>}
          {subTitleText ? <div className={css.orderHelp}>{subTitleText}</div> : null}
        </div>

        <PriceMaybe
          price={price}
          publicData={publicData}
          validListingTypes={validListingTypes}
          intl={intl}
          marketplaceCurrency={marketplaceCurrency}
        />

        {!isTransactionPage ? <div className={css.author}>
          <AvatarSmall user={author} className={css.providerAvatar} />
          <span className={css.providerNameLinked}>
            <FormattedMessage id="OrderPanel.author" values={{ name: authorLink }} />
          </span>
          <span className={css.providerNamePlain}>
            <FormattedMessage id="OrderPanel.author" values={{ name: authorDisplayName }} />
          </span>
        </div> : null}
        <div className={css.loanOfficerContent}>
          <div className={css.loanOfficerTitle}>
            Explaination about get approved here lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
          </div>
        </div>
        {loanOfficeButtonURL}
        {bookingActionButton}
        {!isTransactionPage ? askAQuestions : null}

      </div>
      <Modal
        containerClassName={css.modalContainer}
        id="OrderFormInModal"
        isOpen={isOrderOpen}
        onClose={() => closeOrderModal(history, location)}
        showAsModalMaxWidth={MODAL_BREAKPOINT}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal
      >
        <div className={css.modalHeading}>
          <H1 className={css.heading}>{title}</H1>
        </div>

        <div className={css.orderHeading}>
          {titleDesktop ? titleDesktop : <H2 className={titleClasses}>{title}</H2>}
          {subTitleText ? <div className={css.orderHelp}>{subTitleText}</div> : null}
        </div>

        <PriceMaybe
          price={price}
          publicData={publicData}
          validListingTypes={validListingTypes}
          intl={intl}
          marketplaceCurrency={marketplaceCurrency}
        />
        <div className={css.author}>
          <AvatarSmall user={author} className={css.providerAvatar} />
          <span className={css.providerNameLinked}>
            <FormattedMessage id="OrderPanel.author" values={{ name: authorLink }} />
          </span>
          <span className={css.providerNamePlain}>
            <FormattedMessage id="OrderPanel.author" values={{ name: authorDisplayName }} />
          </span>
        </div>
        {formPage}
      </Modal>
      <div className={css.openOrderForm}>
        <PriceMaybe
          price={price}
          publicData={publicData}
          validListingTypes={validListingTypes}
          intl={intl}
          marketplaceCurrency={marketplaceCurrency}
          showCurrencyMismatch
        />
        <div className={css.ctaOrderFormButtonContent}>
          {loanOfficeButtonURL}
          {bookingActionButton}
        </div>


      </div>
    </div>
  );
};

OrderPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  authorLink: null,
  payoutDetailsWarning: null,
  titleDesktop: null,
  subTitle: null,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
  isTransactionPage: false,
};

OrderPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  validListingTypes: arrayOf(
    shape({
      listingType: string.isRequired,
      transactionType: shape({
        process: string.isRequired,
        alias: string.isRequired,
        unitType: string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  isOwnListing: bool,
  author: oneOfType([propTypes.user, propTypes.currentUser]).isRequired,
  authorLink: node,
  payoutDetailsWarning: node,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  titleDesktop: node,
  subTitle: oneOfType([node, string]),
  onManageDisableScrolling: func.isRequired,

  onFetchTimeSlots: func.isRequired,
  monthlyTimeSlots: object,
  onFetchTransactionLineItems: func.isRequired,
  onContactUser: func,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
  marketplaceCurrency: string.isRequired,
  dayCountAvailableForBooking: number.isRequired,
  marketplaceName: string.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
  isTransactionPage: bool,
};

export default compose(
  withRouter,
  injectIntl
)(OrderPanel);
